import React from "react";
import '../assets/scss/style.scss'
import '../assets/scss/components/infomation.scss'

export default function Layout() {
  return (
    <div className="infomation">
        <h1 className="infomation__name">AYAKO SASAKI</h1>
        <p className="infomation__leadText">Under Construction!!!</p>
        <div className="infomation__links">
          <p className="mail">
            mail :
            <a href="m&#97;i&#108;t&#111;:i&#110;f&#111;&#64;&#115;a&#115;&#97;&#107;&#105;a&#121;&#97;&#107;&#111;&#46;ar&#116;">
              i&#110;f&#111;&#64;&#115;a&#115;&#97;&#107;&#105;a&#121;&#97;&#107;&#111;&#46;ar&#116;
            </a>
          </p>
          <p className="instagram">
            instagram :
            <a
              href="https://www.instagram.com/sasakiayako/?hl=ja"
              target="blank"
            >
              @sasakiayako
            </a>
          </p>
        </div>
    </div>
  );
}
