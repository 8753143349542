import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Infomation from "../components/infomation";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";

// Import Swiper styles
SwiperCore.use([EffectFade, Autoplay]);

import "../assets/scss/page/index.scss";

// markup
const IndexPage = ({ data, location }) => {
  if (typeof window === "undefined") return <SEO />;

  updateViewport();

  window.addEventListener("resize", updateViewport);

  function updateViewport() {
    const vh = window.innerHeight / 100;
    const vw = window.innerWidth / 100;

    const root = document.documentElement;

    // 各カスタムプロパティに`window.innerHeight / 100`,`window.innerWidth / 100`の値をセット
    root.style.setProperty("--vh", `${vh}px`);
    if (vh > vw) {
      root.style.setProperty("--vmax", `${vh}px`);
      root.style.setProperty("--vmin", `${vw}px`);
    } else {
      root.style.setProperty("--vmax", `${vw}px`);
      root.style.setProperty("--vmin", `${vh}px`);
    }
  }

  const { pc, sp } = data;

  const deviceSwiper: any = {
    pc: {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5200,
        disableOnInteraction: false,
      },
      touchRatio: 0,
      speed: 1000,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    },
    sp: {
      loop: true,
      slidesPerView: 1,
      speed: 550,
      direction: "vertical",
    },
  };

  const breakpoint = window.matchMedia("(max-width:768px)") as MediaQueryList;
  const [isMobile, setMobile] = useState(breakpoint.matches);
  const params = breakpoint.matches ? deviceSwiper.sp : deviceSwiper.pc || {};
  breakpoint.addListener(({ matches }) => setMobile(matches));

  return (
    <Layout>
      <div className="index_page">
        <SEO />
        <Infomation />
        <div className="slide">
          <Swiper {...params} observer={true}>
            {!isMobile &&
              pc.nodes.map(({ childImageSharp }) => {
                return (
                  <SwiperSlide key={childImageSharp.id}>
                    <Img
                      fluid={childImageSharp.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </SwiperSlide>
                );
              })}
            {isMobile &&
              sp.nodes.map(({ childImageSharp }) => {
                return (
                  <SwiperSlide key={childImageSharp.id}>
                    <Img
                      fluid={childImageSharp.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    pc: allFile(
      sort: { fields: relativePath }
      filter: { relativePath: { regex: "/^teaser/pc/" } }
    ) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    sp: allFile(
      sort: { fields: relativePath }
      filter: { relativePath: { regex: "/^teaser/sp/" } }
    ) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
